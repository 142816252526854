import { Link, Meta, Title } from "@solidjs/meta";
import type { RouteSectionProps } from "@solidjs/router";
import Sidebar from "~/components/Sidebar/Sidebar";
import PlaygroundHeader from "~/components/playground/PlaygroundHeader";

export default function UsersLayout(props: RouteSectionProps) {
  return (
    <>
      <>
        <Title>Login - JulepAI</Title>
        <Meta name="description" content="JulepAI playground." />
        <Meta name="viewport" content="width=device-width, initial-scale=1" />
      </>
      <main class="h-[calc(100dvh)] flex text-center mx-auto bg-white justify-center items-center">
        <div class="fixed border top-0 w-full p-4 border-gray-200 flex items-center justify-center">
          <img src="/images/logoname.svg" alt="Julep AI logo" />
        </div>
        {props.children}
      </main>
    </>
  );
}
